<script>
import { Line } from "vue-chartjs";
import { dashboardApi } from "../api";
import Loader from "../components/Loader";
export default {
  extends: Line,
    components: {
    Loader,
  },
  data() {
    return {
      data: "",
      showLoader: false,
      datacollection: {
        labels: [],
        datasets: [
          {
            label: "Order",
            data: [],
            borderColor: "#fe7c96",
            backgroundColor: "rgba(254,124,150, .2)",
            borderWidth: 2,
            fill: true,
          },
        ],
      },
      options: {
        scales: {
          yAxes: [
            {
              display: true,
            },
          ],
          xAxes: [
            {
              display: true,
            },
          ],
        },
        legend: {
          display: false,
        },
        elements: {
          point: {
            radius: 0,
          },
          line: {
            tension: 0,
          },
        },
        stepsize: 0,
      },
    };
  },
  mounted() {
    this.orderByDate();
  },
  methods: {
    async orderByDate() {
      (this.datacollection.labels = []), (this.datacollection.datasets[0].data = []);
      this.showLoader = true;
      const { data } = await dashboardApi.stats();
      this.data = data.data.data.orders.orders_by_day;
      this.data.forEach((element) => {
        this.datacollection.labels.push(element.date);
        this.datacollection.datasets[0].data.push(element.orders);
      });
      this.showLoader = false;
      this.renderChart(this.datacollection, this.options);
    },
  },
};
</script>
