<template>
  <section class="dashboard" v-if="dashStats">
    <div class="page-header">
      <h3 class="page-title">
        <!-- <span class="page-title-icon bg-gradient-primary text-white mr-2">
        <i class="mdi mdi-home"></i>
      </span>  -->
        Dashboard
      </h3>
      <nav aria-label="breadcrumb">
        <ul class="breadcrumb">
          <li class="breadcrumb-item active" aria-current="page">
            <span></span>Overview
            <!-- <i class="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i> -->
          </li>
        </ul>
      </nav>
    </div>
    <div class="row mb-3 pb-3" v-if="dashStats">
      <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 grid-margin stretch-card">
        <div class="card card-statistics">
          <div class="card-body">
            <div class="clearfix">
              <div class="float-left">
                <p class="mb-1  font-weight-500 ">Total Orders</p>
                <div class="fluid-container">
                  <h3 class="font-weight-medium mb-0 font-weight-600 ">{{ dashStats.orders.all }}</h3>
                </div>
              </div>
              <div class="float-right">
                <i class="mdi mdi-receipt text-warning icon-lg"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 grid-margin stretch-card">
        <div class="card card-statistics">
          <div class="card-body">
            <div class="clearfix">
              <div class="float-left">
                <p class="mb-1 font-weight-500">Pending Orders</p>
                <div class="fluid-container">
                  <h3 class="font-weight-medium mb-0 font-weight-600 ">
                    {{ dashStats.orders.pending_orders }}
                  </h3>
                </div>
              </div>
              <div class="float-right">
                <i class="mdi mdi-poll-box text-success icon-lg"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 grid-margin stretch-card">
        <div class="card card-statistics">
          <div class="card-body">
            <div class="clearfix">
              <div class="float-left">
                <p class="mb-1 font-weight-500">Shipped Orders</p>
                <div class="fluid-container">
                  <h3 class="font-weight-medium mb-0 font-weight-600">
                    {{ dashStats.orders.shipped_orders }}
                  </h3>
                </div>
              </div>
              <div class="float-right">
                <i class="mdi mdi-cube text-danger icon-lg"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 grid-margin stretch-card">
        <div class="card card-statistics">
          <div class="card-body">
            <div class="clearfix">
              <div class="float-left">
                <p class="mb-1 font-weight-500">Delivered Orders</p>
                <div class="fluid-container">
                  <h3 class="font-weight-medium mb-0 font-weight-600">
                    {{ dashStats.orders.seccessful_orders }}
                  </h3>
                </div>
              </div>
              <div class="float-right">
                <i class="mdi mdi-checkbox-marked-circle-outline text-info icon-lg"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-md-4 stretch-card grid-margin pb-3 mb-3">
        <div class="card bg-gradient-danger card-img-holder text-white">
          <div class="card-body p-4">
            <img src="../../assets/images/circle.svg" class="card-img-absolute" alt="circle-image" />
            <h4 class="font-weight-normal mb-3">Total Orders <i class="mdi mdi-chart-line mdi-24px float-right"></i>
            </h4>
            <h2 class="mb-0">1234</h2>
          </div>
        </div>
      </div>
      <div class="col-md-4 stretch-card grid-margin pb-3 mb-3">
        <div class="card bg-gradient-info card-img-holder text-white">
          <div class="card-body p-4">
            <img src="../../assets/images/circle.svg" class="card-img-absolute" alt="circle-image" />
            <h4 class="font-weight-normal mb-3">Pending Orders <i
                class="mdi mdi-bookmark-outline mdi-24px float-right"></i>
            </h4>
            <h2 class="mb-0">1111</h2>
          </div>
        </div>
      </div>
      <div class="col-md-4 stretch-card grid-margin pb-3 mb-3">
        <div class="card bg-gradient-success card-img-holder text-white">
          <div class="card-body p-4">
            <img src="../../assets/images/circle.svg" class="card-img-absolute" alt="circle-image" />
            <h4 class="font-weight-normal mb-3">Delivered Orders <i class="mdi mdi-diamond mdi-24px float-right"></i>
            </h4>
            <h2 class="mb-0">123</h2>
          </div>
        </div>
      </div> -->
    </div>
    <div class="row">
      <div class=" col-md-12 grid-margin stretch-card mb-3">
        <div class="card">
          <h4 class="card-title">Order By Days</h4>
          <div class="card-body px-2 py-3">
            <orderbyweekChart :height="150"></orderbyweekChart>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 grid-margin stretch-card mb-3">
        <div class="card">
          <h4 class="card-title">Order Status</h4>
          <div class="card-body">
            <orderStatusChart :height="250"></orderStatusChart>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 grid-margin stretch-card mb-3">
        <div class="card">
          <h4 class="card-title">User Status</h4>
          <div class="card-body">
            <userStatusChart :height="250"></userStatusChart>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import orderStatusChart from "../../components/orderStatusChart";
import userStatusChart from "../../components/userStatusChart";
import orderbyweekChart from "../../components/orderbyweekChart";
import { dashboardApi } from "../../api";
import Loader from "../../components/Loader";
export default {
  name: "dashboard",
  components: {
    orderStatusChart,
    orderbyweekChart,
    userStatusChart,Loader
  },
  data() {
    return {
      dashStats: "",
      showLoader: false,
    };
  },
  mounted() {
    this.stats();
  },
  methods: {
    async stats() {
      this.showLoader = true;
      const { data } = await dashboardApi.stats();
      this.dashStats = data.data.data;
      this.showLoader = false;
    },
  },
};
</script>

<style scoped>
.mdi-24px.mdi:before {
  font-size: 44px;
}
</style>
